<template>
  <SelectInput :name="name" :label="label" :options="states" :validate="validate"/>
</template>

<script>
  import us_states from "@/utils/us_states.json";
  import SelectInput from "@/components/form/SelectInput";

  export default {
    name: "StateInput",
    components: {SelectInput},
    props: {
      name: {
        type: String,
        default: 'state',
      },
      label: {
        type: String,
        default: 'state',
      },
      validate: {
        type: [Function, Array],
        required: false
      }
    },
    computed: {
      states: function () {
        return us_states.map(state => ({
          value: state.name,
          key: state.abbreviation,
        }));
      },
    },
  }
</script>

<style scoped>

</style>
