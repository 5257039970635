<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >summary information
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >SUMMARY OF KEY INFORMATION
          </label>
          <div class="form-row">
            <TextField
              name="addressLine1"
              label="street line 1"
              :validate="required"
            />
          </div>
          <div class="form-row">
            <TextField name="addressLine2" label="street line 2" />
          </div>
          <div class="form-row">
            <TextField
              name="city"
              label="city"
              :validate="required"
            />
            <StateInput name="state" label="state" :validate="required" />
            <TextField
              name="zip"
              label="zip"
              :validate="required"
              type="number"
            />
          </div>
          <div class="form-row">
            <TextField label="Unit Number" name="unit" />
            <TextField
              label="Beginning date of Lease"
              name="beginningDateLease"
              type="date"
            />
            <TextField
              label="Ending date of Lease"
              name="endingDateLease"
              type="date"
            />
          </div>
          <div class="form-row">
            <div class="text-title">Number of days notice for termination</div>
            <TextInputSmall name="numberDaysForTermination" type="number" />
          </div>
          <div class="form-row">
            <div class="text-title">Consent for guests staying more than</div>
            <TextInputSmall name="guestsDays" type="number" />
            <div class="text-title">days</div>
          </div>
          <div class="form-row">
            <TextField
              label="Total security deposit"
              name="totalSecurityDeposit"
              type="number"
            />
            <TextField
              label="Animal deposit (if any) "
              name="animalDeposit"
              type="number"
            />
          </div>
          <div class="form-row">
            <label class="text-title"> Security deposit </label>
            <CheckboxInputSmall
              type="checkbox"
              name="securityDepositDoesIncludePetFee"
            />
            <div class="text-title">does</div>
            <CheckboxInputSmall
              type="checkbox"
              name="securityDepositDoesNotIncludePetFee"
            />
            <div class="text-title">does not include an animal deposit</div>
          </div>
          <div class="form-row">
            <div class="text-title">
              Security deposit refund check will be by
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall
              type="checkbox"
              name="securityDepositRefundResidents"
            />
            <div class="text-title">
              one check jointly payable to all residents
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall
              type="checkbox"
              name="securityDepositRefundMailedTo"
            />
            <div class="text-title">one check payable to and mailed to</div>
            <TextField
              name="securityDepositRefundMailedToText"
              class="ml-4"
            />
          </div>
          <div class="form-row">
            <div class="text-title">Number of keys/access devices for</div>
            <TextInputSmall name="noOfDevices" type="number" />
            <div class="text-title">Unit</div>
            <TextInputSmall name="units" type="number" />
            <div class="text-title">Mailbox</div>
            <TextInputSmall name="mailbox" type="number" />
            <div class="text-title">Others</div>
            <TextInputSmall name="others" />
          </div>
          <div class="form-row">
            <div class="text-title">Check here</div>
            <CheckboxInputSmall type="checkbox" name="dwellingFurnished" />
            <div class="text-title">if the dwelling is to be furnished</div>
          </div>
          <div class="form-row">
            <div class="text-title">Check here</div>
            <CheckboxInputSmall type="checkbox" name="concessionAddendum" />
            <div class="text-title">if there is a consession addendum</div>
          </div>
          <div class="form-row text-title">
            Rent to be paid (check all that apply)
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="onsiteManger" />
            <div class="text-title">at the on-site manager's office</div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="throughPayment" />
            <div class="text-title">through the online payment site at</div>
            <TextInputSmall name="atValue" />
          </div>
          <div class="form-row text-title">
            Check here if included in monthly rent:
          </div>
          <div class="form-row flex-wrap">
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="garage" />
              <div class="text-title">garage</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="storage" />
              <div class="text-title">storage</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="carport" />
              <div class="text-title">carport</div>
            </div>
            <div class="form-row mr-8" style="margin-bottom: 1rem">
              <CheckboxInputSmall type="checkbox" name="washerOrDryer" />
              <div class="text-title">washer/dryer</div>
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="other" />
            <div class="text-title">other</div>
            <TextInputSmall name="otherValueMonthlyRent" />
          </div>
          <div class="form-row text-title">Utilies paid by owner:</div>
          <div class="form-row flex-wrap">
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="gas" />
              <div class="text-title">gas</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="water" />
              <div class="text-title">water</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="wastewater" />
              <div class="text-title">wastewater</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="electricity" />
              <div class="text-title">electricity</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="trashOrRecycling" />
              <div class="text-title">trash/recycling</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="cableOrSatellite" />
              <div class="text-title">cable/satellite</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="masterAntenna" />
              <div class="text-title">master antenna</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="internet" />
              <div class="text-title">internet</div>
            </div>
            <div class="form-row mr-8">
              <CheckboxInputSmall type="checkbox" name="stormwaterOrDrainage" />
              <div class="text-title">stormwater/drainage</div>
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="othrValueUtilies" />
            <div class="text-title">other</div>
            <TextInputSmall name="atValuForUtility" />
          </div>
          <div class="form-row">
            <label class="text-title"> You are </label>
            <CheckboxInputSmall
              type="checkbox"
              name="youHaveToBuyRenterInsurance"
            />
            <div class="text-title">
              Required to buy renter's or liability insurance
            </div>
            <CheckboxInputSmall
              type="checkbox"
              name="youDoNotHaveToBuyRenterInsurance"
            />
            <div class="text-title">
              Not required to buy renter's or liability insurance
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">
              Late charges if rent is not paid on or before the
            </div>
            <TextInputSmall name="lateCharge" type="number" />
            <div class="text-title">days</div>
          </div>
          <div class="form-row">
            <div class="text-title">Prorated Rent for (check one)</div>
            <CheckboxInputSmall type="checkbox" name="proratedRentMonth1" />
            <div class="text-title">1st month</div>
            <CheckboxInputSmall type="checkbox" name="proratedRentMonth2" />
            <div class="text-title">2nd month</div>
            <TextInputSmall name="proratedRentInput" />
          </div>
          <div class="form-row w-1/2 pr-8">
            <TextField
              label="Total monthly rent"
              name="totalMonthlyRent"
              type="number"
            />
          </div>
          <div class="form-row">
            <div class="form-row w-1/2 pr-8">
              <TextField
                label="Initial late charge"
                name="intialLateCharge"
                type="number"
                class="ml-0"
              />
            </div>

            <div class="text-title" style="margin-right: 1rem">OR</div>
            <TextInputSmall
              placeholder="%"
              name="intialLateChargeAtPercentage"
              style="margin-top: 28px"
              type="number"
            />
          </div>
          <div class="form-row">
            <div class="form-row w-1/2 pr-8">
              <TextField
                label="Daily late charge"
                name="dailyLateCharge"
                type="number"
                class="ml-0"
              />
            </div>

            <div class="text-title" style="margin-right: 1rem">OR</div>
            <TextInputSmall
              placeholder="%"
              style="margin-top: 28px"
              name="dailyLateChargeAtPercentage"
              type="number"
            />
          </div>
          <div class="form-row">
            <TextField
              label="Returned check charge"
              name="returnedCheckCharge"
              type="number"
            />
            <TextField
              label="Monthly animal rent (if any)"
              name="monthlyAnimalRent"
              type="number"
            />
          </div>
          <div class="form-row">
            <TextField
              label="Animal violation charges: Initial"
              name="animalViolationCharge"
              type="number"
            />
            <TextField label="Daily" name="daily" type="number" />
          </div>
          <div class="form-row">
            <TextField
              label="Monthly pest control (if any)"
              name="monthlyPestControl"
              type="number"
            />
            <TextField
              label="Monthly trash/waste (if any)"
              name="monthlyTrashOrWaste"
              type="number"
            />
          </div>
          <div class="form-row">
            <TextField
              label="Utility connection charge"
              name="utilityConnectionCharge"
              type="number"
            />
            <TextField
              label="Agreed reletting charge"
              name="agreedRelettingCharge"
              type="number"
            />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">SPECIAL PROVISION FACTS</label>
          <div class="form-row">
            <text-input
              id="specialProvisions"
              v-model="specialProvisions"
              rows="5"
              multiline
            />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import StateInput from "@/components/form/StateInput";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    FinalForm,
    TextField,
    StateInput,
    ModalFooter,
    TextInputSmall,
    CheckboxInputSmall,
    TextInput,
  },
  data() {
    return {
      addressLine1: "",
      addressLine2: "",
      unit: "",
      beginningDateLease: "",
      endingDateLease: "",
      numberDaysForTermination: "",
      guestsDays: "",
      totalSecurityDeposit: "",
      animalDeposit: "",
      specialProvision: "",
      securityDepositRefund: "",
      noOfDevices: "",
      units: "",
      mailbox: "",
      others: "",
      dwellingFurnished: false,
      concessionAddendum: false,
      onsiteManger: false,
      throughPayment: false,
      atValue: "",
      garage: false,
      storage: false,
      carport: false,
      washerOrDryer: false,
      otherValueMonthlyRent: "",
      gas: false,
      water: false,
      wastewater: false,
      electricity: false,
      trashOrRecycling: false,
      cableOrSatellite: false,
      masterAntenna: false,
      internet: false,
      stormwaterOrDrainage: false,
      othrValueUtilies: false,
      atValuForUtility: "",
      lateCharge: "",
      proratedRentInput: "",
      totalMonthlyRent: "",
      intialLateCharge: "",
      intialLateChargeAtPercentage: "",
      dailyLateCharge: "",
      dailyLateChargeAtPercentage: "",
      returnedCheckCharge: "",
      monthlyAnimalRent: "",
      animalViolationCharge: "",
      daily: "",
      monthlyPestControl: "",
      monthlyTrashOrWaste: "",
      utilityConnectionCharge: "",
      agreedRelettingCharge: "",
      securityDepositDoesIncludePetFee: "",
      securityDepositDoesNotIncludePetFee: "",
      securityDepositRefundResidents: "",
      securityDepositRefundMailedTo: "",
      securityDepositRefundMailedToText: "",
      youHaveToBuyRenterInsurance: "",
      youDoNotHaveToBuyRenterInsurance: "",
      proratedRentMonth1: "",
      proratedRentMonth2: "",
      footer: {
        primaryButton: "save",
      },
      loading: false,
    };
  },
  computed: {
    initialValues() {
      return {
        addressLine1: "",
        addressLine2: "",
        unit: "",
        beginningDateLease: "",
        endingDateLease: "",
        numberDaysForTermination: "",
        guestsDays: "",
        totalSecurityDeposit: "",
        animalDeposit: "",
        specialProvision: "",
        securityDepositRefund: "",
        noOfDevices: "",
        units: "",
        mailbox: "",
        others: "",
        dwellingFurnished: false,
        concessionAddendum: false,
        onsiteManger: false,
        throughPayment: false,
        atValue: "",
        garage: false,
        storage: false,
        carport: false,
        washerOrDryer: false,
        otherValueMonthlyRent: "",
        gas: false,
        water: false,
        wastewater: false,
        electricity: false,
        trashOrRecycling: false,
        cableOrSatellite: false,
        masterAntenna: false,
        internet: false,
        stormwaterOrDrainage: false,
        othrValueUtilies: false,
        atValuForUtility: "",
        lateCharge: "",
        proratedRentInput: "",
        totalMonthlyRent: "",
        intialLateCharge: "",
        intialLateChargeAtPercentage: "",
        dailyLateCharge: "",
        dailyLateChargeAtPercentage: "",
        returnedCheckCharge: "",
        monthlyAnimalRent: "",
        animalViolationCharge: "",
        daily: "",
        monthlyPestControl: "",
        monthlyTrashOrWaste: "",
        utilityConnectionCharge: "",
        agreedRelettingCharge: "",
        securityDepositDoesIncludePetFee: "",
        securityDepositDoesNotIncludePetFee: "",
        securityDepositRefundResidents: "",
        securityDepositRefundMailedTo: "",
        securityDepositRefundMailedToText: "",
        youHaveToBuyRenterInsurance: "",
        youDoNotHaveToBuyRenterInsurance: "",
        proratedRentMonth1: "",
        proratedRentMonth2: "",
      };
    },
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvision = this.specialProvision;
    },
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

